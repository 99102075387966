<template>
  <div class="p-20">
    <h1 class="mb-8 text-red-500 text-3xl font-bold">{{ response.data.title[0].text }}</h1>
    <pre class="text-white" id="app" v-html="JSON.stringify(response, null, 2)"></pre>
  </div>
</template>

<script>
export default {
  name: 'Api',
  data() {
    return {
      // Initialize "response"
      response: null
    }
  },
  methods: {
    async getContent() {
      // Query the API and assign the response to "response"
      const response = await this.$prismic.client.getSingle('homepage')
      this.response = response
    }
  },
  created() {
    // Call the API query method
    this.getContent()
  }
}
</script>